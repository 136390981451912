import { FC } from "react";

import Wrapper from "@/components/wrapper";
import Button from "@/components/button";
import Pill from "@/components/pill";
import IconWrapper from "./icon-wrapper";

import me from "../../public/me.png";

const Intro: FC = () => {
  return (
    <Wrapper>
      <>
        <div className="flex flex-col gap-1">
          <div className="mb-2 opacity-80">
            <IconWrapper src={me} alt="Zero's personal photo" />
          </div>
          <div>
            <Pill className="inline-flex gap-1 align-center items-center bg-green-950/60 text-green-300 font-bold backdrop-blur-md">
              <>
                <span className="w-2 h-2 bg-green-300 inline-block rounded-full"></span>
                <span>Available → Freelancing</span>
              </>
            </Pill>
          </div>
          <h2 className="text-4xl font-bold leading-tight">
            Hi, I&apos;m Zero
          </h2>
          <h3 className="text-xl text-zinc-400 font-bold leading-tight">
            Product Designer + Developer
          </h3>
          <h3 className="text-md text-zinc-400 font-normal leading-tight">
            Lead Product Designer at SoundCloud. Berlin Based
          </h3>
        </div>
        <div className="flex gap-3">
          <Button href="/about">About Me</Button>
        </div>
      </>
    </Wrapper>
  );
};

export default Intro;
