import { MakeItPop } from "@/icons";
// import Image from "next/image"
import { FramerMotionIcon, NextJSIcon, TailWindIcon } from "@/icons";
import Wrapper from "@/components/wrapper";
import { Tooltip } from "@heroui/tooltip";
// import image1 from '/public/sample-1.png'
// import image2 from '/public/sample-2.png'
// import image3 from '/public/sample-3.png'
// import Link from "next/link"

const Pop = () => {
  return (
    // <section>
    //   <Link href="#" className="group grid transition-colors border border-zinc-50/10 hover:border-zinc-50/20 rounded-[2rem] pt-8 ps-8 break-inside gap-4 overflow-hidden">
    //     <div className="size-12 text-3xl bg-purple-600 border border-zinc-50/10 rounded-xl flex items-center justify-center">
    //       <MakeItPop />
    //     </div>
    //     <div className="flex flex-col gap-1 mb-2">
    //       <h4 className="text-2xl font-bold leading-tight">I design & build websites</h4>
    //       <p className='text-zinc-400 font-normal leading-tight'>I use React, NextJS, Tailwind & Framer Motion</p>
    //     </div>
    //     <div className="relative h-48 overflow-hidden">
    //       <Image src={image1} width={600} height={720} quality={100} placeholder="blur" alt="image"
    //         className="absolute w-3/4 aspect[150/180] shadow-xl shadow-zinc-700 rounded-xl" />
    //       <Image src={image2} width={600} height={720} quality={100} placeholder="blur" alt="image"
    //         className="absolute w-3/4 left-20 group-hover:left-24 aspect[150/180] shadow-xl shadow-zinc-700 rounded-xl transition-all" />
    //       <Image src={image3} width={600} height={720} quality={100} placeholder="blur" alt="image"
    //         className="absolute w-3/4 left-40 group-hover:left-52 aspect[150/180] shadow-xl shadow-zinc-700 rounded-xl transition-all" />
    //     </div>
    //   </Link>
    // </section>
    (<Wrapper className="pb-6">
      <>
        <div className="absolute w-64 h-64 bg-gradient-to-r from-brand/40 rounded-full blur-3xl -bottom-20 -left-20 -z-10"></div>
        <div className="size-12 text-3xl border border-zinc-50/10 rounded-xl flex items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 w-full h-full bg-gradient-to-tr from-brand to-blue-400 -z-10"></div>
          <MakeItPop />
        </div>
        <div className="flex flex-col gap-1">
          <h4 className="text-2xl font-bold leading-tight">
            I design & build websites
          </h4>
          <p className="text-zinc-400 font-normal leading-tight">
            Using React, Next JS, Tailwind & Framer Motion
          </p>
        </div>
        <div className="flex gap-3 text-6xl">
          <Tooltip
            closeDelay={0.1}
            placement="bottom"
            offset={12}
            content="Next JS"
          >
            <div>
              <NextJSIcon className="border border-zinc-50/10 rounded-full" />
            </div>
          </Tooltip>
          <Tooltip
            closeDelay={0.1}
            placement="bottom"
            offset={12}
            content="Tailwind CSS"
          >
            <div>
              <TailWindIcon className="border border-zinc-50/10 rounded-full" />
            </div>
          </Tooltip>
          <Tooltip
            closeDelay={0.1}
            placement="bottom"
            offset={12}
            content="Framer Motion"
          >
            <div>
              <FramerMotionIcon className="border border-zinc-50/10 rounded-full" />
            </div>
          </Tooltip>
        </div>
      </>
    </Wrapper>)
  );
};

export default Pop;
