import { FC } from "react";
import { cn } from "@heroui/theme";

import socialUrls from "@/components/social-urls";
import Wrapper from "@/components/wrapper";
import { Github, LinkedIn, Medium, Twitter } from "@/icons";
import { accessibilityOutline } from "@/utils/misc";
import { Tooltip } from "@heroui/tooltip";

const Social: FC = () => {
  return (
    <Wrapper alt="Social Links">
      <div className="flex justify-between">
        <Tooltip
          closeDelay={0.1}
          placement="bottom"
          offset={4}
          content="Twitter"
        >
          <a
            href={socialUrls.twitter}
            className={cn(
              "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
              accessibilityOutline,
            )}
            rel="noreferrer"
            target="_blank"
            aria-label="Twitter"
          >
            <span className="hidden">Twitter</span>
            <Twitter />
          </a>
        </Tooltip>

        <Tooltip
          closeDelay={0.1}
          placement="bottom"
          offset={4}
          content="LinkedIn"
        >
          <a
            href={socialUrls.linkedin}
            className={cn(
              "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
              accessibilityOutline,
            )}
            rel="noreferrer"
            target="_blank"
            aria-label="LinkedIn"
          >
            <span className="hidden">LinkedIn</span>
            <LinkedIn />
          </a>
        </Tooltip>

        <Tooltip
          closeDelay={0.1}
          placement="bottom"
          offset={4}
          content="Medium"
        >
          <a
            href={socialUrls.medium}
            className={cn(
              "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
              accessibilityOutline,
            )}
            rel="noreferrer"
            target="_blank"
            aria-label="Medium"
          >
            <span className="hidden">Medium</span>
            <Medium />
          </a>
        </Tooltip>

        <Tooltip
          closeDelay={0.1}
          placement="bottom"
          offset={4}
          content="Github"
        >
          <a
            href={socialUrls.github}
            className={cn(
              "text-[2rem] transition-colors text-zinc-400 hover:text-zinc-50",
              accessibilityOutline,
            )}
            rel="noreferrer"
            target="_blank"
            aria-label="Github"
          >
            <span className="hidden">Github</span>
            <Github />
          </a>
        </Tooltip>

      </div>
    </Wrapper>
  );
};

export default Social;
