import { FC, ReactElement } from "react";

import { cn } from "@heroui/theme";

interface Props {
  children: ReactElement | string | number;
  className?: string;
}

const Pill: FC<Props> = ({
  children,
  className = "border border-zinc-50/10 text-zinc-300",
}) => {
  return (
    <span
      className={cn(
        "rounded-full text-xs leading-none py-1 px-1.5 font-normal align-middle",
        className,
      )}
    >
      {children}
    </span>
  );
};

export default Pill;
